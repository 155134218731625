import ky from 'ky';

import { createBearerHeader, parseResponseError } from 'src/common/api-utils';
import { Config } from 'src/config';
import type { IPropertyLabel } from 'src/services/api';

/**
 * Supported property types
 * Should mirror the PropertyType enum in models.py
 * Except backend does not support the empty string one (that's just for UI)
 */
export enum PropertyType {
    None = 'None',
    Apartment = 'Apartment',
    ApartmentBuilding = 'Apartment Building',
    Condo = 'Condo',
    House = 'House',
    Room = 'Room',
    Townhouse = 'Townhouse',
}

export const propertyTypes = Object.values(PropertyType);
export const propertyTypeOptions = propertyTypes.map((type) => ({
    label: type,
    value: type,
}));

export enum NewPropertyField {
    Name = 'name',
    Type = 'type',
    IsVacant = 'is_vacant',
    VacantSince = 'vacant_since',
    Rent = 'rent',

    StreetAddress = 'street_address',
    Unit = 'unit',
    City = 'city',
    State = 'state',
    Zipcode = 'zipcode',

    SquareFeet = 'square_feet',
    NumBedrooms = 'num_bedrooms',
    NumBathrooms = 'num_bathrooms',
    Labels = 'labels',
}

export interface INewProperty {
    [NewPropertyField.Name]?: string | null;

    // location
    [NewPropertyField.StreetAddress]: string;
    [NewPropertyField.Unit]?: string | null;
    [NewPropertyField.City]: string;
    [NewPropertyField.State]: string;
    [NewPropertyField.Zipcode]: string;

    // metadata about the unit
    [NewPropertyField.Type]: PropertyType | null;
    [NewPropertyField.SquareFeet]?: number | null;
    [NewPropertyField.NumBedrooms]: number;
    [NewPropertyField.NumBathrooms]: number;
    [NewPropertyField.Labels]?: IPropertyLabel[];

    // current data about the unit
    [NewPropertyField.Rent]?: number | null;
    [NewPropertyField.IsVacant]: boolean;
    [NewPropertyField.VacantSince]?: Date | null;
}

/**
 * A property that exists in the DB and is returned by the API server
 * NOTE: it has the id field set
 */
export interface IProperty {
    id: number;
    name: string;

    // location
    street_address: string;
    // may be null
    unit: string | null;
    city: string;
    state: string;
    zipcode: string;

    // metadata about the property itself
    type: PropertyType | null;
    square_feet: number | null;
    num_bedrooms: number;
    num_bathrooms: number;

    image_path: string | null;
    is_vacant: boolean;
    // this is a date (without time)
    // it's parsed by the API fetch method
    vacant_since: Date | null;
    rent: number | null;

    // this is a timestamp (date and time)
    // it's parsed by the API fetch method
    created_at: Date;

    occupancy: IPropertyOccupancy[];
    showing_scheduling_link: IShowingSchedulingLink | null;
    labels: IPropertyLabel[];
    application_link: IPropertyApplicationLink | null;
}

/**
 * Simplified property object that only contains the fields we need for the dashboard.
 */
export interface ISimplifiedProperty {
    id: number;
    name: string;
    is_vacant: boolean;
}

/**
 * Should be in sync with PropertyOccupancy in models.py
 */
export interface IPropertyOccupancy {
    id: number;
    property_id: number;
    /**
     * Just date, no time
     */
    start_date: Date;
    /**
     * Just date, no time
     */
    end_date: Date | null;
}

export interface IGetAllPropertiesResponse {
    properties: IProperty[];
}

/**
 * Uses fetch API rather than ky
 *
 * Return all properties managed by the current landlord user.
 * This API will parse the response.
 * On error it will throw an ApiError with the appropriate code.
 * @throws {ApiError} on failure
 */
export const getAllProperties = async (accessToken: string): Promise<IProperty[]> => {
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/properties/all';
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    };

    const r = await fetch(url.toString(), {
        method: 'GET',
        mode: 'cors',
        headers: headers,
    });
    if (r.ok) {
        const j = await r.json();
        j.properties.forEach((property: IProperty) => {
            // convert vacant_since from string to Date
            if (property.vacant_since) {
                property.vacant_since = new Date(property.vacant_since);
            }
            // convert created_at from string to Date
            if (property.created_at) {
                property.created_at = new Date(property.created_at);
            }
            // parse the dates for each property occupancy
            property.occupancy.forEach((occupancy: IPropertyOccupancy) => {
                // format: YYYY-MM-DD
                // this is a string as returned by the server
                occupancy.start_date = new Date(occupancy.start_date);
                // can be string or null
                if (occupancy.end_date) {
                    // this is a string as returned by the server
                    occupancy.end_date = new Date(occupancy.end_date);
                }
            });
        });
        return j.properties as IProperty[];
    } else {
        const err = await parseResponseError(r, 'Failed to fetch properties');
        throw err;
    }
};

interface IGetAllPropertiesPartialResponse {
    properties: Partial<IProperty>[];
}

/**
 * Uses ky
 *
 * Return all properties managed by the current landlord user, but filter the fields.
 * NOTE: not all fields are supported.
 *
 * This API will parse the response.
 * @param {string[]} fields - fetch only these fields from the backend (for performance)
 */
export async function getAllPropertiesPartial(accessToken: string, fields: string[]) {
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/properties/all';
    if (fields) {
        url.searchParams.set('fields', fields.join(','));
    }
    const headers = {
        'Authorization': `Bearer ${accessToken}`,
    };
    const resp = await ky.get(url.toString(), {
        headers: headers
    }).json() as IGetAllPropertiesPartialResponse;
    const properties = resp.properties;
    properties.forEach((property) => {
        if (property.occupancy) {
            // parse the dates for each property occupancy
            property.occupancy.forEach((occupancy: IPropertyOccupancy) => {
                // format: YYYY-MM-DD
                // this is a string as returned by the server
                occupancy.start_date = new Date(occupancy.start_date);
                // can be string or null
                if (occupancy.end_date) {
                    // this is a string as returned by the server
                    occupancy.end_date = new Date(occupancy.end_date);
                }
            });
        }
    });
    return properties;
}

/**
 * The raw property that is returned from the server
 */
export type IRawProperty = Omit<IProperty, 'created_at' | 'vacant_since'> & {
    created_at: string;
    vacant_since: string | null;
};

export interface IGetPropertyDetailsParsedResponse {
    property: IRawProperty;
    /**
     * Whether the current user is the owner of this property
     * If false, means the property belongs to the leasing team
     */
    is_owner: boolean;
}

export interface IShowingSchedulingLink {
    id: number;
    property_id: number;
    leasing_team_id: number;
    link: string;
    // this is a timestamp (date and time)
    // parsed from JSON by frontend code
    created_at: Date;
    // this is a timestamp (date and time)
    // parsed from JSON by frontend code
    modified_at: Date;
}

interface IGetShowingSchedulingLinkResponse {
    link: string;
}

/**
 * Uses ky
 */
export async function getShowingSchedulingLink(accessToken: string, propertyId: number): Promise<IGetShowingSchedulingLinkResponse> {
    if (!accessToken) {
        throw new Error('api token is required');
    }
    if (!propertyId) {
        throw new Error('property ID is required');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/properties/${propertyId}/showing-scheduling-link`;
    const headers = createBearerHeader(accessToken);
    return ky.get(url.toString(), {
        headers: headers,
    }).json() as Promise<IGetShowingSchedulingLinkResponse>;
}

interface ISetShowingSchedulingLinkResponse {
    status: 'success';
    msg: string;
    showing_scheduling_link: IShowingSchedulingLink;
    action: 'create' | 'update';
}

/**
 * Uses ky
 */
export async function setShowingSchedulingLink(accessToken: string, propertyId: number, link: string): Promise<ISetShowingSchedulingLinkResponse> {
    if (!accessToken) {
        throw new Error('api token is required');
    }
    if (!propertyId) {
        throw new Error('property ID is required');
    }
    if (!link) {
        throw new Error('link is required');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/properties/${propertyId}/showing-scheduling-link`;
    const headers = createBearerHeader(accessToken);
    const data = {
        link: link,
    };
    const resp = await ky.post(url.toString(), {
        headers: headers,
        json: data,
    }).json() as ISetShowingSchedulingLinkResponse;
    // parse created_at to Date object
    // the server responds with created_at as string in ISO format
    resp.showing_scheduling_link.created_at = new Date(resp.showing_scheduling_link.created_at);
    // parse modified_at to Date object
    // the server responds with modified_at as string in ISO format
    resp.showing_scheduling_link.modified_at = new Date(resp.showing_scheduling_link.modified_at);
    return resp;
}

export interface IGetPropertyApplicationLinkResponse {
    link: string;
}

export interface IPropertyApplicationLink {
    id: number;
    property_id: number;
    leasing_team_id: number;
    link: string;
    // this is a timestamp (date and time)
    // parsed from JSON by frontend code
    created_at: Date;
    // this is a timestamp (date and time)
    // parsed from JSON by frontend code
    modified_at: Date;
}

interface ISetPropertyApplicationLinkResponse {
    status: 'success';
    msg: string;
    property_application_link: IPropertyApplicationLink;
    action: 'create' | 'update';
}

/**
 * Uses ky
 */
export async function setPropertyApplicationLink(accessToken: string, propertyId: number, link: string): Promise<ISetPropertyApplicationLinkResponse> {
    if (!accessToken) {
        throw new Error('api token is required');
    }
    if (!propertyId) {
        throw new Error('property ID is required');
    }
    if (!link) {
        throw new Error('link is required');
    }
    const url = new URL(Config.backendServer);
    url.pathname = `/api/landlord/properties/${propertyId}/application-link`;
    const headers = createBearerHeader(accessToken);
    const data = {
        link: link,
    };
    const resp = await ky.post(url.toString(), {
        headers: headers,
        json: data,
    }).json() as ISetPropertyApplicationLinkResponse;
    // parse created_at to Date object
    // the server responds with created_at as string in ISO format
    resp.property_application_link.created_at = new Date(resp.property_application_link.created_at);
    // parse modified_at to Date object
    // the server responds with modified_at as string in ISO format
    resp.property_application_link.modified_at = new Date(resp.property_application_link.modified_at);
    return resp;
}

export interface IGetAllPropertyLabelsResponse {
    labels: IPropertyLabel[];
}

async function getAllPropertyLabels(accessToken: string): Promise<IGetAllPropertyLabelsResponse> {
    const url = new URL(Config.backendServer);
    url.pathname = '/api/landlord/properties/labels/all';
    const headers = createBearerHeader(accessToken);
    return ky.get(url.toString(), {
        headers: headers,
    }).json() as Promise<IGetAllPropertyLabelsResponse>;
}

export default {
    getAll: getAllProperties,
    getAllPartial: getAllPropertiesPartial,
    getShowingSchedulingLink,
    setShowingSchedulingLink,
    setPropertyApplicationLink,
    getAllLabels: getAllPropertyLabels
};
